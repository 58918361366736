import * as React from 'react';
import { Link, Search, useNavigate } from 'react-router-dom';
import * as Models from '../models/index';
import { useContext, useEffect, useReducer } from 'react';
import { Button, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { formatDate } from '../utils';
import { Formik, FormikErrors, FormikProps, FormikValues } from 'formik';
import * as FormUtils from '../components/formUtils/index';
import { AccountService, SearchIn } from '../services/AccountService';
import AuthenticationContext from '../components/context/AuthenticationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import UserProfileService from '../services/UserProfileService';
import LettersService from '../services/LettersService';

import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import '../styles/quill.snow-custom.css';

const PAGE_SIZE = 10;

interface Props {
}

type State = {
    isLoading: boolean,
    approvedUsers: Models.UserProfileListView[],
    pendingApprovedUsers: Models.UserProfileListView[],
    unApprovedUsers: Models.UserProfileListView[],
    approvedLetter: string,
    pendingApprovedLetter: string,
    unapprovedLetter: string,
}

const initialState: State = {
    isLoading: false,
    approvedUsers: [],
    pendingApprovedUsers: [],
    unApprovedUsers: [],
    approvedLetter: '',
    pendingApprovedLetter: '',
    unapprovedLetter: '',
}

export const Letters = (props: Props) => {

    const navigate = useNavigate();

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialState
    );

    const search = async () => {

        setState({
            isLoading: true
        });

        // 
        const approvedResult = await UserProfileService.listforletters({ type: "Approved" });

        if (!approvedResult.hasErrors) {
            setState({
                approvedUsers: approvedResult.value.items
            })
        }

        const pendingApprovedResult = await UserProfileService.listforletters({ type: "PendingApproved" });

        if (!pendingApprovedResult.hasErrors) {
            setState({
                pendingApprovedUsers: pendingApprovedResult.value.items
            })
        }

        const unApprovedResult = await UserProfileService.listforletters({ type: "Unapproved" });

        if (!unApprovedResult.hasErrors) {
            setState({
                unApprovedUsers: unApprovedResult.value.items
            })
        }

        const letters = await LettersService.get(1);

        if (!letters.hasErrors) {
            setState({
                approvedLetter: letters.value.approvedLetter,
                pendingApprovedLetter: letters.value.pendingApprovedLetter,
                unapprovedLetter: letters.value.unapprovedLetter
            })
        }

    }

    const handleApprovedChange = (html) => {
        setState({
            approvedLetter: html
        })
    }
    const handlePendingChange = (html) => {
        setState({
            pendingApprovedLetter: html
        })
    }
    const handleUnapprovedChange = (html) => {
        setState({
            unapprovedLetter: html
        })
    }

    const saveLetters = async () => {
        let letters = new Models.Letters();
        letters.id = 1;
        letters.approvedLetter = state.approvedLetter;
        letters.pendingApprovedLetter = state.pendingApprovedLetter;
        letters.unapprovedLetter = state.unapprovedLetter;
        const saveLetter = await LettersService.save(letters);
    }

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }
    /* 
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]


    useEffect(() => {
        search();
    }, [])

    return <>
        <h1>Letters</h1>

        <h3>Placeholders</h3>
        <p>Use these codes to replace with the applicants actual values.</p>
        <p>
            #AIMGID# - AIMG ID<br />
            #FirstName# - First Name<br />
            #LastName# - Last Name<br />
            #Email# - Email<br />
            #PendingDocuments# - Pending Documents <br />
            #MissingDocuments# - Missing Documents <br />
        </p>

        <div className="row">
            <div className="col-8">
                <div className="form-group mb-4">
                    <label className="form-label lead ">Approved Letter</label>
                    <ReactQuill
                        theme="snow"
                        onChange={handleApprovedChange}
                        value={state.approvedLetter}
                        modules={modules}
                        formats={formats}
                        bounds={'.app'}
                        placeholder="Edit Letter"
                    />
                </div>
            </div>
            <div className="col-4">
                These Users Meet This Criteria ({state.approvedUsers.length} total)
                <div className="letters-for-list">
                    {state.approvedUsers.map((item, index) =>
                        <div><a href={'/app-submission-view/' + item.userProfileId} target="_blank">{item.surname}, {item.givenNames}</a></div>
                    )}
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-8">
                <div className="form-group mb-4">
                    <label className="form-label lead ">Pending Approved Letter</label>
                    <ReactQuill
                        theme="snow"
                        onChange={handlePendingChange}
                        value={state.pendingApprovedLetter}
                        modules={modules}
                        formats={formats}
                        bounds={'.app'}
                        placeholder="Edit Letter"
                    />
                </div>
            </div>
            <div className="col-4">
                These Users Meet This Criteria ({state.pendingApprovedUsers.length} total)
                <div className="letters-for-list">
                    {state.pendingApprovedUsers.map((item, index) =>
                        <div><a href={'/app-submission-view/' + item.userProfileId} target="_blank">{item.surname}, {item.givenNames}</a></div>
                    )}
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-8">
                <div className="form-group mb-4">
                    <label className="form-label lead ">Unapproved Letter</label>
                    <ReactQuill
                        theme="snow"
                        onChange={handleUnapprovedChange}
                        value={state.unapprovedLetter}
                        modules={modules}
                        formats={formats}
                        bounds={'.app'}
                        placeholder="Edit Letter"
                    />
                </div>
            </div>
            <div className="col-4">
                These Users Meet This Criteria ({state.unApprovedUsers.length} total)
                <div className="letters-for-list">
                    {state.unApprovedUsers.map((item, index) =>
                        <div><a href={'/app-submission-view/' + item.userProfileId} target="_blank">{item.surname}, {item.givenNames}</a></div>
                    )}
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col">
                <hr />
                <div className="d-flex justify-content-between mb-3">
                    <button type="button" disabled={true} className="btn btn-outline-primary disabled">Discard Changes</button>
                    &nbsp;
                    <button type="button" className="btn btn-primary" onClick={saveLetters}>Save</button>
                </div>
            </div>
        </div>
    </>

};