import * as React from "react";
import * as FormUtils from '../formUtils/index';
import * as ArrayUtils from "typescript-array-utils";
import { AppSubmissionReviewMedicalDegree, AppSubmissionReviewToTeamReviewEligiblePending, AppSubmissionReviewToTeamReviewNotEligible, AppSubmissionReviewToTeamReviewCaRMSEligible, AppSubmissionReviewToCaRMSEligiblePending, AppUserFile, AppUserInfo, DropDownItem, FileType, FileTypeEligiblePending, FileTypeToAppUserFile, SubmissionCycleSettings, SubmissionCycleSettingsToFileTypeExtensionDate, TeamReviewEligiblePending, TeamReviewNotEligible, TeamReviewCaRMSEligible, UserProfile } from "../../models/index";
import { formatDate, downloadFile, bytesToFriendly } from '../../utils';
import { FormikProps, FormikValues, useField, useFormikContext, Formik } from "formik";
import { Button, FormFeedback, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent, Table, TabPane } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import cn from 'classnames';
import { useContext, useEffect, useReducer, useState } from 'react';
import { UserProfileEditorFields } from './UserProfileEditorFields';
import { AppUserFileGroupReview, AppUserFileWorkflowStep } from "../../models/AppUserFile";
import AuthenticationContext from "../context/AuthenticationContext";
import AppUserFilesService from "../../services/AppUserFileService";
import { ValidationSchema } from '../../models/AppUserFile';
import { AccountService } from "../../services/AccountService";
import { TeamReviewEligiblePendingService } from "../../services/TeamReviewEligiblePendingService";
import { TeamReviewNotEligibleService } from "../../services/TeamReviewNotEligibleService";
import { TeamReviewCaRMSEligibleService } from "../../services/TeamReviewCaRMSEligibleService";
import { AppSubmissionReviewToAppUsers } from "../../models/AppSubmissionReviewToAppUsers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { AppSubmissionReviewMedicalDegreeService } from "../../services/AppSubmissionReviewMedicalDegreeService";
import { ChatByUser } from "../chat/adminChat/ChatByUser";
import { FileTypeEligiblePendingService } from "../../services/FileTypeEligiblePendingService";
import { UserLetter } from "../letters/UserLetter";
import { AdministrativeException } from "../../models/AdministrativeException";
import { AdministrativeExceptionService } from "../../services/AdministrativeExceptionService";
import GenericEditor from "../editors/GenericEditor";
import { UserAdministrativeException } from "../../models/UserAdministrativeException";
import UserAdministrativeExceptionService from "../../services/UserAdministrativeExceptionService";
import { UserAdministrativeExceptionAdminFields } from "./UserAdministrativeExceptionAdminFields";

interface IProps {
    values: UserProfile;
}

type State = {
    activeTab: 'dashboard' | 'data' | 'files' | 'adminExec' | 'review' | 'chat' | 'letter';
    adminUsers: AppUserInfo[];
    appUser?: AppUserInfo;
    userAE?: UserAdministrativeException
}

const initialValues: State = {
    activeTab: 'dashboard',
    adminUsers: [],
    appUser: undefined,
    userAE: undefined
}

export function ApplicationSubmissionEditorFields(props: FormikProps<FormikValues>) {

    const values = props.values as UserProfile;

    const [state, setState] = useReducer(
        (prevState: State, newState: Partial<State>) => ({ ...prevState, ...newState }),
        initialValues
    );

    const authCtx = useContext(AuthenticationContext);

    //const isReadOnly = values.userProfileId.length > 0 && values.isApplicationSubmission == true && authCtx.currentUser?.isAdmin;

    const canSeeReviewTab = values.userProfileId.length > 0 && values.isApplicationSubmission == true && authCtx.currentUser?.isAdmin;
    const canSeeMessageTab = values.userProfileId.length > 0 && values.isApplicationSubmission == true && authCtx.currentUser?.isAdmin;
    // const canSeeLetterTab = values.userProfileId.length > 0 && values.isApplicationSubmission == true && authCtx.currentUser?.isAdmin;
    const canSeeLetterTab = false; // TO DO - Reenable

    const loadAdmins = async () => {
        var result = await AccountService.getAllAdmins();

        if (!result.hasErrors) {
            setState({
                adminUsers: result.value
            })
        }
    }

    const loadUserOfThisApplication = async () => {

        var result = await AccountService.appUserInfoById(values.appUserId);
        if (!result.hasErrors) {
            setState({ appUser: result.value });
        }
    }

    const getAdminExecText = (id) => {
        switch (id) {
            case 1:
                return "AE Submitted";
                break;
            case 2:
                return "Additional Information Required";
                break;
            case 3:
                return "Reviewing AE Request";
                break;
            case 4:
                return "AE Decision / Granted";
                break;
            case 5:
                return "AE Decision / Denied";
                break;
            case 6:
                return "AE Decision Not Applicable";
                break;
            case 7:
                return "AE Request Cancelled";
                break;
        }
    }

    useEffect(() => {

        loadAdmins();
        loadUserOfThisApplication();

        UserAdministrativeExceptionService.getAE(values.userProfileId || '').then(userAE => {
            state.userAE = userAE.value
        })

    }, []);

    const setSoloAlbertaResidencyScoreFinal = new function() {
        var score1 = 0;
        if (values.appSubmissionReview.soloAlbertaResidencyScore1)
            score1 = values.appSubmissionReview.soloAlbertaResidencyScore1;
        var score2 = 0;
        if (values.appSubmissionReview.soloAlbertaResidencyScore2)
            score2 = values.appSubmissionReview.soloAlbertaResidencyScore2;
        var score3 = 0;
        if (values.appSubmissionReview.soloAlbertaResidencyScore3)
            score3 = values.appSubmissionReview.soloAlbertaResidencyScore3;
        var score4 = 0;
        if (values.appSubmissionReview.soloAlbertaResidencyScore4)
            score4 = values.appSubmissionReview.soloAlbertaResidencyScore4;
        var score5 = 0;
        if (values.appSubmissionReview.soloAlbertaResidencyScore5)
            score5 = values.appSubmissionReview.soloAlbertaResidencyScore5;
        var score6 = 0;
        if (values.appSubmissionReview.soloAlbertaResidencyScore6)
            score6 = values.appSubmissionReview.soloAlbertaResidencyScore6;

        values.appSubmissionReview.soloAlbertaResidencyScoreFinal = score1 + score2 + score3 + score4 + score5 + score6;
    }

    const setTeamAlbertaResidencyScoreFinal = new function () {
        var score1 = 0;
        if (values.appSubmissionReview.teamAlbertaResidencyScore1)
            score1 = values.appSubmissionReview.teamAlbertaResidencyScore1;
        var score2 = 0;
        if (values.appSubmissionReview.teamAlbertaResidencyScore2)
            score2 = values.appSubmissionReview.teamAlbertaResidencyScore2;
        var score3 = 0;
        if (values.appSubmissionReview.teamAlbertaResidencyScore3)
            score3 = values.appSubmissionReview.teamAlbertaResidencyScore3;
        var score4 = 0;
        if (values.appSubmissionReview.teamAlbertaResidencyScore4)
            score4 = values.appSubmissionReview.teamAlbertaResidencyScore4;
        var score5 = 0;
        if (values.appSubmissionReview.teamAlbertaResidencyScore5)
            score5 = values.appSubmissionReview.teamAlbertaResidencyScore5;
        var score6 = 0;
        if (values.appSubmissionReview.teamAlbertaResidencyScore6)
            score6 = values.appSubmissionReview.teamAlbertaResidencyScore6;

        values.appSubmissionReview.teamAlbertaResidencyScoreFinal = score1 + score2 + score3 + score4 + score5 + score6;
    }

    return <>
        <h3>Application submission for period <span className="text-primary">{formatDate(values.submissionCycleSettings.applicationStart)} - {formatDate(values.submissionCycleSettings.applicationEnd)}</span></h3>
        <UserInfoView values={values} />
        <Nav tabs className="mt-4">
            <NavItem>
                <NavLink
                    className={cn({ "active": state.activeTab == 'dashboard' })}
                    onClick={() => setState({ activeTab: 'dashboard' })}
                >
                    Dashboard
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={cn({ "active": state.activeTab == 'data' })}
                    onClick={() => setState({ activeTab: 'data' })}>
                    Profile
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={cn({ "active": state.activeTab == 'files' })}
                    onClick={() => setState({ activeTab: 'files' })}
                >
                    Your Files
                </NavLink>
            </NavItem>

            {canSeeReviewTab && <NavItem>
                <NavLink
                    className={cn({ "active": state.activeTab == 'review' })}
                    onClick={() => setState({ activeTab: 'review' })}
                >
                    Review
                </NavLink>
            </NavItem>}

            {canSeeReviewTab && <NavItem>
                <NavLink
                    className={cn({ "active": state.activeTab == 'adminExec' })}
                    onClick={() => setState({ activeTab: 'adminExec' })}
                >
                    Administrative Exceptions
                </NavLink>
            </NavItem>}

            {canSeeMessageTab && <NavItem>
                <NavLink
                    className={cn({ "active": state.activeTab == 'chat' })}
                    onClick={() => setState({ activeTab: 'chat' })}
                >
                    Messages (CHAT)
                </NavLink>
            </NavItem>}
            {canSeeLetterTab && <NavItem>
                <NavLink
                    className={cn({ "active": state.activeTab == 'letter' })}
                    onClick={() => setState({ activeTab: 'letter' })}
                >
                    Letter
                </NavLink>
            </NavItem>}

        </Nav>
        <TabContent activeTab={state.activeTab} >
            <TabPane tabId="dashboard" className="pt-3">
                <h2>Dashboard</h2>

                <div className="disclaimer-box for-fields col-sm-12">
                    <p className="disclaimer">Certain supporting documents uploads are available again.  Please use the Your Files option above to upload documents.</p>
                </div>

                {/*<div className="border border-2 rounded p-2 mb-4">*/}
                {/*    <h3>Externship</h3>*/}
                {/*    <FormUtils.GroupSetCheckbox*/}
                {/*        fieldName="isExternship"*/}
                {/*        title="User Is Externship"*/}
                {/*        className="mb-3"*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className="border border-2 rounded p-2 mb-4">*/}
                {/*    <h3>Administrative Exceptions</h3>*/}

                {/*    <FormUtils.GroupSetRadioAsync<AdministrativeException>*/}
                {/*        fieldName="administrativeExceptionId"*/}
                {/*        title="Administrative Exception"*/}
                {/*        getDisplayText={x => x.value}*/}
                {/*        getValue={x => x.id.toString()}*/}
                {/*        loadDataSource={() => AdministrativeExceptionService.list()}*/}
                {/*        required*/}
                {/*    />*/}

                {/*</div>*/}
            </TabPane>
            <TabPane tabId="data" className="pt-3">

                <UserProfileEditorFields {...props} />
            </TabPane>
            <TabPane tabId="files" className="pt-3">

                {values.appUserFiles
                    /*.filter((val) => {

                        return (values.albertaResidencyType || '').length > 0
                            && (val.fileType.visibleForResidence || '').length > 0
                            && (val.fileType.visibleForResidence || '').toLocaleLowerCase().indexOf((values.albertaResidencyType || '').toLowerCase()) > -1
                    })
                    */
                    .map((item, index: number) => {

                        //var isVisible = (values.albertaResidencyType || '').length > 0
                        //    && (item.fileType.visibleForResidence || '').length > 0
                        //    && (item.fileType.visibleForResidence || '').toLocaleLowerCase().indexOf((values.albertaResidencyType || '').toLowerCase()) > -1

                        //if (!isVisible)
                        //    return null;

                        var extensionDateSetting = (values.submissionCycleSettings
                            .submissionCycleSettingsToFileTypeExtensionDates || [])
                            .find(extDate => extDate.fileTypeId == item.fileType.id);

                        return <FileTypeToAppUserFileView
                            model={item}
                            rowNumber={index + 1}
                            key={item.fileType.id.toString()}
                            onChange={(newItem) => {

                                var updatedItems = ArrayUtils.replace(values.appUserFiles, index, newItem);
                                props.setFieldValue('appUserFiles', updatedItems, true);
                            }}
                            showActions={values.userProfileId.length > 0}
                            adminUsers={state.adminUsers}
                            userProfileId={values.userProfileId}
                            extensionDateSetting={extensionDateSetting}
                        />
                    }
                    )}
                {props.errors.appUserFiles as string && <FormFeedback className="d-block"><div>{props.errors.appUserFiles as any}</div></FormFeedback>}
            </TabPane>

            {canSeeReviewTab &&
                <TabPane tabId="adminExec" className="pt-3">
                    <>
                        <GenericEditor<UserAdministrativeException, string>
                            getById={id => UserAdministrativeExceptionService.getAE(id || '')}
                            save={model => UserAdministrativeExceptionService.save(model)}
                            formikFields={UserAdministrativeExceptionAdminFields}
                            validationSchema={ValidationSchema}
                            getId={x => x.userProfileId}
                            stringToId={x => x}
                            shouldTryToLoad={id => true}
                            disableSaveButton={false}
                            errorsMap={{

                            }}
                        />
                    </>
                </TabPane>
            }

            {canSeeReviewTab &&
                <TabPane tabId="review" className="pt-0">

                    <p><strong>View the <a href="https://docs.google.com/spreadsheets/d/1LeqvSprc1RkVi_262MhzwLSYwY8aEs5r/edit?usp=drive_link&ouid=110667184917006808154&rtpof=true&sd=true" target="_blank" rel="noreferrer">Application File Rubrics</a> <i className="fa fa-download" aria-hidden="true"></i></strong></p>

                    <div className="border border-2 rounded p-2 mb-4">
                        <h3>Fee payment</h3>
                        <FormUtils.GroupSetCheckbox
                            fieldName="appSubmissionReview.feePaymentReceived"
                            title="Fee Payment received"
                            className="mb-3"
                        />
                    </div>

                    <div className="border border-2 rounded p-2 mb-4">
                        <h3>Documents verified to this file from physiciansapply.ca</h3>
                        <FormUtils.GroupSetDropdown<string>
                            fieldName="appSubmissionReview.docMccQ1StatementResult"
                            title="MCC QE1 Statement of Results"
                            dropdownProps={{
                                dataSource: ['Pending', 'Shared', 'Retake'],
                                getId: x => x,
                                renderItem: x => x,
                                renderSelectedItem: x => x,
                                selectedValue: values.appSubmissionReview.docMccQ1StatementResult,
                                allowNullSelection: true,
                                nullSelectionText: 'Select'
                            }}
                        />

                        <FormUtils.GroupSetDropdown<string>
                            fieldName="appSubmissionReview.docNacExamStatementResult"
                            title="NAC Exam Statement of Results"
                            dropdownProps={{
                                dataSource: ['Pending', 'Shared', 'Retake'],
                                getId: x => x,
                                renderItem: x => x,
                                renderSelectedItem: x => x,
                                selectedValue: values.appSubmissionReview.docNacExamStatementResult,
                                allowNullSelection: true,
                                nullSelectionText: 'Select'
                            }}
                        />

                        <FormUtils.GroupSetDropdownAsync<AppSubmissionReviewMedicalDegree, number>
                            fieldName="appSubmissionReview.medicalDegreeId"
                            title="Medical Degree"
                            dropdownProps={{
                                //dataSource: ['Not yet conferred', 'Not Shared', 'Not at least "Received/Accepted"', 'Not ECFMG Verified'],
                                loadDataSource: () => AppSubmissionReviewMedicalDegreeService.list(),
                                getId: x => x.id,
                                renderItem: x => x.name,
                                renderSelectedItem: x => x.name,
                                selectedValue: values.appSubmissionReview.medicalDegreeId,
                                allowNullSelection: true,
                                nullSelectionText: 'Select'
                            }}
                        />
                    </div>

                    <div className="border border-2 rounded p-2 mb-4">
                        <h3>Change of Name Document</h3>
                        <FormUtils.GroupSetCheckbox
                            fieldName="appSubmissionReview.changeOfNameDocument"
                            title="Change of Name Document"
                            className="mb-3"
                        />
                    </div>

                    {state.userAE && <>
                        <div className="border border-2 rounded p-2 mb-4">
                            <h3>Administrative Exceptions</h3>

                            {state.userAE && state.userAE.administrativeException && <>
                                <div>
                                    <strong>AE 1</strong>:
                                    {state.userAE.administrativeException}
                                </div>
                            </>}

                            {state.userAE && state.userAE.administrativeException2 && <>
                                <div>
                                    <strong>AE 2</strong>:
                                    {state.userAE.administrativeException2}
                                </div>
                            </>}

                            {state.userAE && state.userAE.administrativeException3 && <>
                                <div>
                                    <strong>AE 3</strong>:
                                    {state.userAE.administrativeException3}
                                </div>
                            </>}

                        </div>
                    </>}

                    <div className="border border-2 rounded p-2 mb-4">
                        <h3>Individual Review</h3>

                        {/*<FormUtils.GroupSetCheckbox*/}
                        {/*    fieldName="appSubmissionReview.useMccCandidateToSearchPhysiciansapply"*/}
                        {/*    title="Use MCC Candidate Code to search physiciansapply.ca"*/}
                        {/*/>*/}

                        <FormUtils.GroupSetDatePicker
                            fieldName="appSubmissionReview.reviewStepDate"
                            title="Review Date"
                            noTime={true}
                            pickerConfig={{
                                minDate: new Date(2023, 1, 1),
                                maxDate: new Date()
                            }}
                            date={values.appSubmissionReview.reviewStepDate}
                        />

                        <FormUtils.GroupSetDropdownAsync<AppUserInfo, string>
                            fieldName="appSubmissionReview.reviewStepUserId"
                            title="Reviewing User"
                            dropdownProps={{
                                //dataSource: state.adminUsers,
                                loadDataSource: () => AccountService.getAllAdmins(),
                                getId: x => x.id,
                                renderItem: x => `${x.firstName} ${x.lastName} ${x.userName}`,
                                renderSelectedItem: x => `${x.firstName} ${x.lastName}`.trim().length > 0 ? `${x.firstName} ${x.lastName}` : `${x.userName}`,

                                selectedValue: values.appSubmissionReview.reviewStepUserId,
                                allowNullSelection: true,
                                nullSelectionText: 'Select'
                            }}
                        />

                        <div className="border border-2 rounded p-2 mb-4">
                            <h4>Alberta Residency Score</h4>
                            <Row>
                                <Col sm="6">
                                    <div className="border border-2 rounded p-2 mb-4">
                                        <h5>Option 1</h5>
                                        <FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.soloAlbertaResidencyScore5"
                                        title="High School Transcript"
                                        dropdownProps={{
                                            dataSource: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.soloAlbertaResidencyScore5,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setSoloAlbertaResidencyScoreFinal
                                        }}
                                        />
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="border border-2 rounded p-2 mb-4">
                                        <h5>Option 2</h5>
                                        <FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.soloAlbertaResidencyScore6"
                                        title="Post-Secondary Transcript"
                                        dropdownProps={{
                                            dataSource: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.soloAlbertaResidencyScore6,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setSoloAlbertaResidencyScoreFinal
                                        }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="border border-2 rounded p-2 mb-4">
                                <h5>Option 3</h5>
                                <Row>
                                    <Col sm="6"><FormUtils.GroupSetDropdown<number>
                                            fieldName="appSubmissionReview.soloAlbertaResidencyScore1"
                                            title="Statutory Declaration (Stat Dec)"
                                            dropdownProps={{
                                                dataSource: [0,1,2],
                                                getId: x => x,
                                                renderItem: x => x,
                                                renderSelectedItem: x => x,
                                                selectedValue: values.appSubmissionReview.soloAlbertaResidencyScore1,
                                                allowNullSelection: true,
                                                nullSelectionText: 'Select',
                                                onSelectedItemChanged: setSoloAlbertaResidencyScoreFinal,
                                            }}
                                        /></Col>
                                    <Col sm="6"><FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.soloAlbertaResidencyScore2"
                                        title="3rd Party Verification"
                                        dropdownProps={{
                                            dataSource: [0, 1, 2, 3],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.soloAlbertaResidencyScore2,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setSoloAlbertaResidencyScoreFinal
                                        }}
                                    /></Col>
                                </Row>
                                <Row>
                                    <Col sm="6"><FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.soloAlbertaResidencyScore3"
                                        title="Driver's License or non-Operator's License"
                                        dropdownProps={{
                                            dataSource: [0, 1, 2],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.soloAlbertaResidencyScore3,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setSoloAlbertaResidencyScoreFinal
                                        }}
                                    /></Col>
                                    <Col sm="6"><FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.soloAlbertaResidencyScore4"
                                        title="AB PHC (Alberta Personal Health Care Card)"
                                        dropdownProps={{
                                            dataSource: [0, 1],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.soloAlbertaResidencyScore4,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setSoloAlbertaResidencyScoreFinal
                                        }}
                                    /></Col>
                                </Row>
                            </div>
                            <Row>
                                <Col><FormUtils.GroupSetText
                                    fieldName="appSubmissionReview.soloAlbertaResidencyScoreFinal"
                                    title="Final Score"
                                /></Col>
                            </Row>
                        </div>

                        <FormUtils.GroupSetTextArea
                            fieldName="appSubmissionReview.soloReviewNotes"
                            title="Solo Review Notes"
                        />
                    </div>

                    <div className="border border-2 rounded p-2 mb-4">
                        <h3>CaRMS Eligible Status</h3>

                        <FormUtils.GroupSetCheckbox
                            fieldName="appSubmissionReview.caRmsEligibleStatus"
                            title="CaRMS Eligible"
                        />

                        {/*<FormUtils.GroupSetCheckbox*/}
                        {/*    fieldName="appSubmissionReview.caRmsEligiblePending"*/}
                        {/*    title="CaRMS Eligible Pending (waiting for documents)"*/}
                        {/*/>*/}
                        <FormUtils.GroupSetDropdownMultiSelectAsync<FileTypeEligiblePending, AppSubmissionReviewToCaRMSEligiblePending>
                            fieldName="appSubmissionReview.caRMSEligiblePending"
                            title="CaRMS Eligible Pending (waiting for documents)"
                            dropdownProps={{
                                loadDataSource: () => FileTypeEligiblePendingService.list(),
                                getKey: x => x.id.toString(),
                                isChecked: (x, ds) => ds.filter(y => y.fileTypeId == x.id).length > 0,
                                renderItem: x => x.description,
                                //renderSelected: ds => 'selected',
                                createSelectedItem: (item) => { return { fileTypeId: item.id, userProfileId: values.userProfileId } as AppSubmissionReviewToCaRMSEligiblePending },
                                removeSelectedItem: (values, item) => {
                                    return values.filter(x => x.fileTypeId != item.id);
                                },
                                findBySelectedId: (values, selected) => { return values.find(x => x.id == selected.fileTypeId) as FileType },
                                noSelctionText: <>Select</>
                            }}
                        />

                        <FormUtils.GroupSetCheckbox
                            fieldName="appSubmissionReview.caRmsEligibleUndecided"
                            title="CaRMS Eligible Pending  (Executive review required)"
                            className="mb-3"
                        />

                        <FormUtils.GroupSetCheckbox
                            fieldName="appSubmissionReview.caRmsProfileErrors"
                            title="CaRMS Profile Errors (Executive Review Required)"
                        />

                        <FormUtils.GroupSetCheckbox
                            fieldName="appSubmissionReview.caRmsIneligibleStatus"
                            title="CaRMS Ineligible"
                        />

                        <FormUtils.GroupSetTextArea
                            fieldName="appSubmissionReview.additionalNotes"
                            title="Additional Notes"
                        />
                    </div>

                    <div className="border border-2 rounded p-2 mb-4">
                        <h3>Team Review</h3>
                        <FormUtils.GroupSetDatePicker
                            fieldName="appSubmissionReview.teamReviewDate"
                            title="Team Review Date"
                            noTime={true}
                            pickerConfig={{
                                minDate: new Date(2023, 1, 1),
                                maxDate: new Date()
                            }}
                            date={values.appSubmissionReview.teamReviewDate}
                        />

                        <FormUtils.GroupSetDropdownMultiSelectAsync<AppUserInfo, AppSubmissionReviewToAppUsers>
                            fieldName="appSubmissionReview.teamReviewUsers"
                            title="Team Review FRC"
                            dropdownProps={{
                                loadDataSource: () => AccountService.getAllAdmins(),
                                getKey: x => x.id,
                                isChecked: (x, ds) => ds.filter(y => y.appUserId == x.id).length > 0,
                                renderItem: x => `${x.firstName} ${x.lastName}`.trim().length > 0 ? `${x.firstName} ${x.lastName}` : `${x.userName}`,
                                //renderSelected: (ds) => <>click here</>,
                                createSelectedItem: (item) => { return { appUserId: item.id, userProfileId: values.userProfileId } }
                                ,
                                removeSelectedItem: (values, item) => {
                                    return values.filter(x => x.appUserId != item.id);
                                },
                                findBySelectedId: (values, selected) => { return values.find(x => x.id == selected.appUserId) as AppUserInfo },
                                noSelctionText: <>Select</>
                            }}
                        />

                        <FormUtils.GroupSetDropdownMultiSelectAsync<TeamReviewEligiblePending, AppSubmissionReviewToTeamReviewEligiblePending>
                            fieldName="appSubmissionReview.teamReviewEligiblePending"
                            title="Team Review Eligible PENDING"
                            dropdownProps={{
                                //dataSource: ['IELTS result', 'MCCQE1 pass', 'G/E Check', 'Other'],
                                loadDataSource: () => TeamReviewEligiblePendingService.list(),
                                getKey: x => x.id.toString(),
                                isChecked: (x, ds) => ds.filter(y => y.teamReviewEligiblePendingId == x.id).length > 0,
                                renderItem: x => x.name,
                                //renderSelected: x => x,
                                createSelectedItem: (item) => { return { teamReviewEligiblePendingId: item.id, userProfileId: values.userProfileId } as AppSubmissionReviewToTeamReviewEligiblePending },
                                removeSelectedItem: (values, item) => {
                                    return values.filter(x => x.teamReviewEligiblePendingId != item.id);
                                },
                                findBySelectedId: (values, selected) => { return values.find(x => x.id == selected.teamReviewEligiblePendingId) as TeamReviewEligiblePending },
                                noSelctionText: <>Select</>
                            }}
                        />

                        <FormUtils.GroupSetDropdownMultiSelectAsync<TeamReviewNotEligible, AppSubmissionReviewToTeamReviewNotEligible>
                            fieldName="appSubmissionReview.teamReviewNotEligible"
                            title="Team Review Not Eligible"
                            dropdownProps={{
                                //dataSource: ['No docs', 'AB Res', 'Status in Canada', 'ELP Insufficient', 'MCCQE1 Receipt: Apr - Jun / July', 'MD > 2022', 'Notary', 'Other'],
                                loadDataSource: () => TeamReviewNotEligibleService.list(),
                                getKey: x => x.id.toString(),
                                isChecked: (x, ds) => ds.filter(y => y.teamReviewNotEligibleId == x.id).length > 0,
                                renderItem: x => x.name,
                                //renderSelected: ds => 'selected',
                                createSelectedItem: (item) => { return { teamReviewNotEligibleId: item.id, userProfileId: values.userProfileId } as AppSubmissionReviewToTeamReviewNotEligible },
                                removeSelectedItem: (values, item) => {
                                    return values.filter(x => x.teamReviewNotEligibleId != item.id);
                                },
                                findBySelectedId: (values, selected) => { return values.find(x => x.id == selected.teamReviewNotEligibleId) as TeamReviewNotEligible },
                                noSelctionText: <>Select</>
                            }}
                        />

                        <FormUtils.GroupSetDropdownMultiSelectAsync<TeamReviewCaRMSEligible, AppSubmissionReviewToTeamReviewCaRMSEligible>
                            fieldName="appSubmissionReview.teamReviewCaRMSEligible"
                            title="CaRMS Eligible (upon completion of MMI) "
                            dropdownProps={{
                                //dataSource: ['No docs', 'AB Res', 'Status in Canada', 'ELP Insufficient', 'MCCQE1 Receipt: Apr - Jun / July', 'MD > 2022', 'Notary', 'Other'],
                                loadDataSource: () => TeamReviewCaRMSEligibleService.list(),
                                getKey: x => x.id.toString(),
                                isChecked: (x, ds) => ds.filter(y => y.teamReviewCaRMSEligibleId == x.id).length > 0,
                                renderItem: x => x.name,
                                //renderSelected: ds => 'selected',
                                createSelectedItem: (item) => { return { teamReviewCaRMSEligibleId: item.id, userProfileId: values.userProfileId } as AppSubmissionReviewToTeamReviewCaRMSEligible },
                                removeSelectedItem: (values, item) => {
                                    return values.filter(x => x.teamReviewCaRMSEligibleId != item.id);
                                },
                                findBySelectedId: (values, selected) => { return values.find(x => x.id == selected.teamReviewCaRMSEligibleId) as TeamReviewCaRMSEligible },
                                noSelctionText: <>Select</>
                            }}
                        />

                        <div className="border border-2 rounded p-2 mb-4">
                            <h4>Alberta Residency Score</h4>
                            <Row>
                                <Col sm="6">
                                    <div className="border border-2 rounded p-2 mb-4">
                                        <h5>Option 1</h5>
                                        <FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.teamAlbertaResidencyScore5"
                                        title="High School Transcript"
                                        dropdownProps={{
                                            dataSource: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.teamAlbertaResidencyScore5,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setTeamAlbertaResidencyScoreFinal
                                        }}
                                        />
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div className="border border-2 rounded p-2 mb-4">
                                        <h5>Option 2</h5>
                                        <FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.teamAlbertaResidencyScore6"
                                        title="Post-Secondary Transcript"
                                        dropdownProps={{
                                            dataSource: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.teamAlbertaResidencyScore6,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setTeamAlbertaResidencyScoreFinal
                                        }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="border border-2 rounded p-2 mb-4">
                                <h5>Option 3</h5>
                                <Row>
                                    <Col sm="6"><FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.teamAlbertaResidencyScore1"
                                        title="Statutory Declaration (Stat Dec)"
                                        dropdownProps={{
                                            dataSource: [0, 1, 2],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.teamAlbertaResidencyScore1,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setTeamAlbertaResidencyScoreFinal
                                        }}
                                    /></Col>
                                    <Col sm="6"><FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.teamAlbertaResidencyScore2"
                                        title="3rd Party Verification"
                                        dropdownProps={{
                                            dataSource: [0, 1, 2, 3],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.teamAlbertaResidencyScore2,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setTeamAlbertaResidencyScoreFinal
                                        }}
                                    /></Col>
                                </Row>
                                <Row>
                                    <Col sm="6"><FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.teamAlbertaResidencyScore3"
                                        title="Driver's License or non-Operator's License"
                                        dropdownProps={{
                                            dataSource: [0, 1, 2],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.teamAlbertaResidencyScore3,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setTeamAlbertaResidencyScoreFinal
                                        }}
                                    /></Col>
                                    <Col sm="6"><FormUtils.GroupSetDropdown<number>
                                        fieldName="appSubmissionReview.teamAlbertaResidencyScore4"
                                        title="AB PHC (Alberta Personal Health Care Card)"
                                        dropdownProps={{
                                            dataSource: [0, 1],
                                            getId: x => x,
                                            renderItem: x => x,
                                            renderSelectedItem: x => x,
                                            selectedValue: values.appSubmissionReview.teamAlbertaResidencyScore4,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select',
                                            onSelectedItemChanged: setTeamAlbertaResidencyScoreFinal
                                        }}
                                    /></Col>
                                </Row>
                            </div>
                            
                            <Row>
                                <Col><FormUtils.GroupSetText
                                    fieldName="appSubmissionReview.teamAlbertaResidencyScoreFinal"
                                    title="Final Score"
                                /></Col>
                            </Row>
                        </div>

                        <FormUtils.GroupSetTextArea
                            fieldName="appSubmissionReview.teamReviewNotes"
                            title="Team Review Notes"
                        />
                    </div>

                    {/*
                    <FormUtils.GroupSetCheckbox
                        fieldName="appSubmissionReview.supportingDocReceivedByDeadline"
                        title="Supporting documents received by deadline"
                    />
                    {values.appSubmissionReview.supportingDocReceivedByDeadline &&
                        <>

                            <FormUtils.GroupSetDatePicker
                                fieldName="appSubmissionReview.docsReceivedDate"
                                title="Late Documents Received on Date"
                                noTime={true}
                                pickerConfig={{
                                    minDate: new Date(2023, 1, 1),
                                    maxDate: new Date()
                                }}
                                date={values.appSubmissionReview.docsReceivedDate}
                            />

                            <FormUtils.GroupSetTextArea
                                fieldName="appSubmissionReview.lateDocumentNames"
                                title="Name of Late Document(s)"
                            />
                        </>
                    }
                    */}

                </TabPane>
            }
            {
                canSeeMessageTab &&
                <TabPane tabId="chat" className="pt-3">
                    {state.appUser && <ChatByUser user={state.appUser} active={true} />}
                </TabPane>
            }
            {
                canSeeLetterTab &&
                <TabPane tabId="letter" className="pt-3">
                    {state.appUser && <UserLetter user={state.appUser} />}
                </TabPane>
            }

        </TabContent>
    </>;
}

const FileTypeToAppUserFileView = (props: {
    model: FileTypeToAppUserFile;
    rowNumber: number;
    onChange: (model: FileTypeToAppUserFile) => void,
    showActions: boolean;
    adminUsers: AppUserInfo[];
    userProfileId: string;
    extensionDateSetting?: SubmissionCycleSettingsToFileTypeExtensionDate
}) => {

    const hasFiles = props.model.appUserFiles.length > 0;
    const authCtx = useContext(AuthenticationContext);

    const getNewAppUserEditModel = () => {

        var result = new AppUserFile();

        // result.fileMimeType = props.file.fileMimeType;
        result.fileTypeId = props.model.fileType.id;
        // result.id = props.file.id;
        result.userProfileId = props.userProfileId;
        result.fileDescription = '';
        result.isForAppSubmission = true;

        return result;
    }

    const todayIsNotPassedExtendedDay = (date?: Date): boolean => {

        if (!date)
            return false;

        return new Date() <= date;
    }

    return <div className="card mb-3">
        <div className="card-body">
            <h5><a href={props.model.fileType.fileLinkUrl} target="_blank" className="text-decoration-none"><span className="text-decoration-underline">{props.model.fileType.description}</span> {props.model.fileType.mandatoryOnApply && <sup className="required-mark">*</sup>}</a></h5>

            {props.model.fileType.fileAdditionalMessage && <div className="additional-message">{props.model.fileType.fileAdditionalMessage}</div>}
            {hasFiles && <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">File Name</th>
                        <th scope="col">Upload Date</th>
                        <th scope="col">File Size</th>
                        <th scope="col">View</th>
                        {props.showActions &&
                            <>
                            {authCtx.currentUser?.isAdmin == true && <>
                            <th scope="col">Actions / Status</th>
                                <th scope="col">Solo Review</th>
                                <th scope="col">Group Review</th>
                                <th scope="col">Executive Review</th>
                            </>}
                            </>
                        }
                    </tr>
                </thead>
                <tbody>

                    {props.model.appUserFiles.map((item, index) =>
                        <AppFileRowView key={item.id}
                            {...{
                                file: item,
                                fileType: props.model.fileType,
                                rowNumber: index + 1,
                                closeDate: props.model.closeDate,
                                onChange: (newItem) => {

                                    //console.log('appfilerow changed', newItem, index);
                                    //var _index = props.model.appUserFiles.findIndex(x => x.id == newItem.id);

                                    var updatedFiles = ArrayUtils.replace(props.model.appUserFiles, index, newItem);
                                    var newModel = { ...props.model };
                                    newModel.appUserFiles = updatedFiles;

                                    props.onChange(newModel);
                                },
                                showActions: props.showActions,
                                adminUsers: props.adminUsers

                            }}
                        />
                    )}


                </tbody>
            </table>
            }

            {authCtx.currentUser?.isAdmin == false && todayIsNotPassedExtendedDay(props.extensionDateSetting?.extendedDate) &&
                <div>
                    <AddFileComponent initialValue={getNewAppUserEditModel()} onSave={(x) => {
                        var newFilesSet = props.model.appUserFiles.concat([x]);

                        var newModel = { ...props.model };
                        newModel.appUserFiles = newFilesSet;

                        props.onChange(newModel);

                    }} />

                    {props.extensionDateSetting && <span className="ms-2 additional-message">Available for upload until <strong>{formatDate(props.extensionDateSetting.extendedDate)}</strong></span>}
                </div>
            }
            {/*!hasFiles && <>Please use Your Supporting Documents to submit files</>*/}

        </div>
    </div>
}


type UserInfoViewState = {
    isLoading: boolean;
    model?: AppUserInfo
}

const UserInfoView = (props: {
    values: UserProfile
}) => {

    const [state, setState] = useReducer(
        (prevState: UserInfoViewState, newState: Partial<UserInfoViewState>) => ({ ...prevState, ...newState }),
        {
            isLoading: false,
            model: undefined
        }
    );

    const loadModel = async () => {

        setState({ isLoading: true });


        var result = await AccountService.appUserInfoById(props.values.appUserId);

        if (!result.hasErrors) {
            setState({
                isLoading: false,
                model: result.value
            })
        }
    }


    useEffect(() => {

        loadModel();

    }, [props.values.appUserId]);

    return <>
        <h5>{props.values.surname} {props.values.formerSurname}  {props.values.givenNames}, {props.values.dateOfBirth != null && formatDate(props.values.dateOfBirth as Date)}
        </h5>
        {state.model && <>
            <a href={'mailto:' + state.model.email}><FontAwesomeIcon icon={solid("envelope")} className="me-2" />{state.model.email}</a>
            <a href={'tel:' + props.values.telephone} className="ms-4"><FontAwesomeIcon icon={solid("phone")} className="me-2" />{props.values.telephone}</a>
        </>}
    </>
}




const dsAdmin: DropDownItem<number>[] = [{
    value: AppUserFileWorkflowStep.Pending,
    text: 'Ready For Review'
},
{
    value: AppUserFileWorkflowStep.Rejected,
    text: 'Reupload Required'
}, {
    value: AppUserFileWorkflowStep.Accepted,
    text: 'Accepted'
}];

type AppFileRowViewState = {
    isSaving: boolean;
    showModal: boolean;
    editObject: AppUserFile;
    model: AppUserFile;
}


const AppFileRowView = (props: {
    file: AppUserFile;
    fileType: FileType;
    closeDate: Date;
    rowNumber: number;
    onChange: (file: AppUserFile) => void,
    showActions: boolean;
    adminUsers: AppUserInfo[];
}) => {

    const getNewAppUserEditModel = () => {

        var result = new AppUserFile();

        result.fileMimeType = props.file.fileMimeType;
        result.fileTypeId = props.fileType.id;
        result.id = props.file.id;
        result.userProfileId = props.file.userProfileId;
        result.fileDescription = props.file.fileDescription;
        result.isForAppSubmission = props.file.isForAppSubmission;

        return result;
    }

    const [state, setState] = useReducer(
        (prevState: AppFileRowViewState, newState: Partial<AppFileRowViewState>) => ({ ...prevState, ...newState }),
        {
            isSaving: false,
            showModal: false,
            editObject: getNewAppUserEditModel(),
            model: props.file
        }
    );

    const authCtx = useContext(AuthenticationContext);

    useEffect(() => {
        setState({ model: props.file });
    }, [props.file]);

    return <tr>
        <td>{props.rowNumber}</td>
        <td>
            {state.model.id && <><a href={`/api/appuserfile/open/${state.model.id}/${state.model.fileName}`} target="_blank">
                {state.model.fileName}
            </a>
                <div className="small">{state.model.fileSubtypeDescription}</div>
            </>
            }
        </td>
        <td className={new Date(state.model.fileDate) > new Date(props.closeDate) ? "after-close" : ""}>{formatDate(state.model.fileDate)}</td>
        <td>{bytesToFriendly(state.model.fileSize)}</td>
        <td>
            {state.model.id && <Button color="secondary" onClick={(e) => { e.preventDefault(); downloadFile(`api/appuserfile/download/${state.model.id}`, state.model.fileName) }}>
                <i className="fa fa-download" aria-hidden="true"></i> view
            </Button>}
        </td>

        {props.showActions &&
            <>
            {authCtx.currentUser?.isAdmin == true &&
                    
                    <td>
                    {/*
                            <Formik initialValues={state.model} enableReinitialize={true}
                                onSubmit={(values, actions) => {
                                    props.onChange(values);
                                }}
                            >
                                {(formikState) =>
                                    <>
                                        <FormUtils.GroupSetDropdown<DropDownItem<number>>
                                            fieldName="workflowStep"
                                            title={null}
                                            dropdownProps={{
                                                dataSource: dsAdmin,
                                                renderItem: x => x.text,
                                                getId: x => x.value,
                                                renderSelectedItem: x => x.text,
                                                selectedValue: formikState.values.workflowStep,
                                                onSelectedItemChanged: (item) => { formikState.submitForm() }
                                            }}
                                        />
                                    </>
                                }
                            </Formik>


                        {authCtx.currentUser?.isAdmin == false
                            && state.model.workflowStep == AppUserFileWorkflowStep.Pending
                            && <>Review pending</>
                        }

                        {authCtx.currentUser?.isAdmin == false
                            && state.model.workflowStep == AppUserFileWorkflowStep.Accepted
                            && <>Accepted</>
                        }

                        {authCtx.currentUser?.isAdmin == false
                            && state.model.workflowStep == AppUserFileWorkflowStep.Rejected
                        && <AddFileComponent initialValue={state.editObject} onSave={(x) => setState({ model: x })} />}
                    */}
                    </td>
                }

                {authCtx.currentUser?.isAdmin == true
                    && <Formik initialValues={state.model} enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            console.log('submiting..', values);
                            props.onChange(values);
                        }}
                    >
                        {(formikState) =>
                            <>
                                <td>
                                {/*
                                    <FormUtils.GroupSetDropdown<AppUserInfo>
                                        fieldName="soloReviewId"
                                        title={null}
                                        dropdownProps={{
                                            dataSource: props.adminUsers,
                                            getId: x => x.id,
                                            renderItem: x => `${x.firstName} ${x.lastName}`,
                                            renderSelectedItem: x => `${x.firstName} ${x.lastName}`.trim().length > 0 ? `${x.firstName} ${x.lastName}` : `${x.userName}`,
                                            onSelectedItemChanged: () => { formikState.submitForm(); },
                                            selectedValue: state.model.soloReviewId,
                                            allowNullSelection: true,
                                            nullSelectionText: 'Select'
                                        }}
                                        onValueChanged={() => { formikState.submitForm(); }}
                                />
                                */}
                                </td>
                            <td>
                                {/*
                                    {props.adminUsers.length > 0 && <FormUtils.GroupSetDropdownMultiSelect<AppUserInfo, AppUserFileGroupReview>
                                        fieldName="groupReview"
                                        title={null}
                                        dropdownProps={{
                                            dataSource: props.adminUsers,
                                            getKey: x => x.id,
                                            isChecked: (x, ds) => ds.filter(y => y.userId == x.id).length > 0,
                                            renderItem: x => `${x.firstName} ${x.lastName}`.trim().length > 0 ? `${x.firstName} ${x.lastName}` : `${x.userName}`,
                                            renderSelected: (ds) => <>click here</>,
                                            createSelectedItem: (item) => {
                                                return {
                                                    fileId: props.file.id,
                                                    userId: item.id
                                                }
                                            },
                                            removeSelectedItem: (values, item) => {
                                                return values.filter(x => x.userId != item.id);
                                            },
                                            findBySelectedId: (values, selected) => { return values.find(x => x.id == selected.userId) as AppUserInfo },
                                            noSelctionText: <>Select</>
                                        }}
                                        onValueChanged={() => { formikState.submitForm(); }}
                                />}
                                */}
                                </td>

                            <td>
                                {/*
                                    <FormUtils.GroupSetCheckbox
                                        fieldName="executiveReview"
                                        title=""
                                        onValueChanged={() => {
                                            //console.log('FormUtils.GroupSetCheckbox, onValueChanged');
                                            formikState.submitForm();
                                        }}
                                />
                                */}
                                </td>
                            </>
                        }
                    </Formik>
                }

            </>
        }
    </tr>
}

type AddFileComponentState = {
    showModal: boolean;
    isSaving: boolean;

}

const AddFileComponent = (props: {
    initialValue: AppUserFile,
    onSave: (model: AppUserFile) => void
}) => {


    const [state, setState] = useReducer(
        (prevState: AddFileComponentState, newState: Partial<AddFileComponentState>) => ({ ...prevState, ...newState }),
        {
            showModal: false,
            isSaving: false
        }
    );


    return <>
        <Button color="primary" onClick={() => { setState({ showModal: true }) }}>Upload</Button>
        <Formik
            enableReinitialize={true}
            validationSchema={ValidationSchema}
            validateOnBlur={false}
            initialValues={props.initialValue}
            onSubmit={async (values, actions) => {

                setState({ isSaving: true });
                values.workflowStep = AppUserFileWorkflowStep.Pending

                var saveResult = await AppUserFilesService.save(values);


                if (!saveResult.hasErrors) {
                    setState({
                        showModal: false,
                        isSaving: false
                    })

                    props.onSave(saveResult.value);
                    actions.resetForm();

                } else {
                    setState({ isSaving: false })
                }

            }}
            validateOnMount={true}
        >
            {(formikState) => {

                //let canSave = formikState.isValid && formikState.dirty && !state.isLoading;
                const fileSizeTooLow = formikState.values.fileSize != undefined && formikState.values.fileSize < 1024;
                const canSave = formikState.isValid && !state.isSaving && !fileSizeTooLow;

                return (
                    <Modal isOpen={state.showModal}>
                        <ModalHeader toggle={() => { setState({ showModal: !state.showModal }) }}>Replace file</ModalHeader>

                        <ModalBody>
                            <FormUtils.GroupSetFileUpload
                                title=""
                                fieldName="fileBinnary"
                                controlSize="large"
                            />
                        </ModalBody>

                        <ModalFooter>
                            <Button color="primary"
                                disabled={!canSave}
                                onClick={() => { formikState.submitForm() }}>
                                {state.isSaving && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Save
                            </Button>{' '}
                            <Button color="secondary" onClick={() => {
                                formikState.resetForm();
                                setState({ showModal: false })
                            }}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                )
            }}
        </Formik>
    </>

}